import { ChakraProvider } from "@chakra-ui/react";

export const StyleguideProviderFactory =
  (theme: any) =>
  ({ children }) => {
    return (
      <ChakraProvider theme={theme} resetCSS={true}>
        {children}
      </ChakraProvider>
    );
  };
